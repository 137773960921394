import React from 'react';
import {useTranslation} from "react-i18next";
import {Link} from "react-scroll/modules";

const Cta = () => {
    const {t} = useTranslation();

    return (
        <section className="cta">
            <div className="cta-content">
                <div className="container">
                    <h2>Be social.<br/>Be velee.</h2>
                    <Link isDynamic={true} spy={true} smooth={true} duration={500} to="social-media" href={""}
                          className="btn btn-outline btn-xl js-scroll-trigger">
                        {t("cta-follow")}
                    </Link>

                </div>
            </div>
            <div className="overlay"/>
        </section>
    )
}

export default Cta