import React from 'react';
import Logo from '../img/velee-150.png';
import Screen from '../img/iphone_x_startscreen.png';
import {Link} from "react-scroll";
import {firebaseAnalytics} from "../firebase/firebase-init";

export default class Header extends React.Component {

    render() {
        return (
            <header className="masthead">
                <div className="container h-100">
                    <div className="row h-100">
                        <div className="col-lg-7 my-auto">
                            <div className="header-content mx-auto">
                               
                                <img className="mb-3" src={Logo} width="300" alt=""/>
                                <h5 className="mb-4">enjoy team spirit</h5>
                                <Link isDynamic={true} spy={true} smooth={true} duration={500} to="download" href={""}
                                      className="btn btn-outline btn-xl js-scroll-trigger" onClick={() => {
                                    firebaseAnalytics.then((analytics) => {
                                        analytics.logEvent("download_click")
                                    })
                                }}>
                                    Download
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-5 my-auto">
                            <div className="device-container">
                                <img src={Screen} className="img-fluid" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </header>


        )
    }
}