import React from 'react';
import Screen from '../img/planning_de.png';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBicycle, faRoute, faRunning, faUserFriends} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";

const Features = () => {

    const {t} = useTranslation();

    return (
        <section className="features" id="features">
            <div className="container">
                <div className="section-heading text-center">
                    <h2>{t("features-headline")}</h2>
                    <p className="text-muted">{t("features-underline")}</p>
                    <hr/>
                </div>
                <div className="row">
                    <div className="col-lg-4 my-auto">
                        <div className="device-container">
                            <img src={Screen} className="img-fluid" alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-8 my-auto">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="feature-item">
                                        {/*<i className="fa fa-running text-primary"></i>*/}
                                        <i className="text-primary">
                                            <FontAwesomeIcon icon={faRunning} color="#6887a0"/>
                                        </i>
                                        <h3>{t("features-activities-headline")}</h3>
                                        <p className="text-muted">{t("features-activities-text")}</p>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="feature-item">
                                        <i className="text-primary">
                                            <FontAwesomeIcon icon={faUserFriends} color="#6887a0"/>
                                        </i>
                                        <h3>{t("features-clubs-headline")}</h3>
                                        <p className="text-muted">{t("features-clubs-text")}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="feature-item">
                                        <i className="text-primary">
                                            <FontAwesomeIcon icon={faRoute} color="#6887a0"/>
                                        </i>
                                        <h3>{t("features-team-headline")}</h3>
                                        <p className="text-muted">{t("features-team-text")}</p>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="feature-item">
                                        <i className="text-primary">
                                            <FontAwesomeIcon icon={faBicycle} color="#6887a0"/>
                                        </i>
                                        <h3>{t("features-partners-headline")}</h3>
                                        <p className="text-muted">{t("features-partners-text")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Features