import {firebaseAnalytics} from "../firebase/firebase-init";

const Promotion = () => {

    const doRedirect = () => {
        let device = getMobileOperatingSystem()
        let link = window.location.href;

        link = link.split("?")

        let mobile = false;
        let url = "https://velee.cc"
        if (device === "ios") {
            mobile = true;
            url = "https://apps.apple.com/de/app/stansday/id1539169967"
            firebaseAnalytics.then((analytics) => {
                analytics.logEvent("redirect_ios")
            });
        } else if (device === "android") {
            mobile = true;
            url = "https://play.google.com/store/apps/details?id=com.stansday.app"
            firebaseAnalytics.then((analytics) => {
                analytics.logEvent("redirect_android")
            });
        } else {
            firebaseAnalytics.then((analytics) => {
                analytics.logEvent("redirect_website")
            });
        }

        if(link && link.length > 1){
            if (link[1].startsWith("flyer")) {
                let flyer = link[1].split("=")
                if(flyer && flyer.length > 1){
                    firebaseAnalytics.then((analytics) => {
                        analytics.logEvent(`redirect_flyer_${flyer[1]}`)
                    })
                }
            } else {
                firebaseAnalytics.then((analytics) => {
                    analytics.logEvent(`redirect_${link[1]}`)
                })
            }
        }

        redirectToUri(url, mobile)
    };


    const redirectToUri = (url, mobile) => {
        setTimeout(function () {
            window.location = url;
        }, 25);
        if(mobile) {
            window.location = `stansday://`;
        }
    }

    const getMobileOperatingSystem = () => {
        let userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {

            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {

            return "android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {

            return "ios";
        }

        return "unknown";
    }

        doRedirect()


}

export default Promotion;