import React from 'react';
import Logo from '../img/velee-150.png'

const NavigationNoMenu = () => {
        return (
            <nav className="navbar navbar-expand-lg  fixed-top" id="mainNav">
                <div className="container">
                    <a href={process.env.PUBLIC_URL + "/"}>
                        <img src={Logo} width="80" alt=""/>
                    </a>
                </div>
            </nav>
        );
};

export default NavigationNoMenu