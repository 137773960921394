import React from 'react';
import {firebaseAnalytics} from "../firebase/firebase-init";

export default class SocialMedia extends React.Component{
    render(){
        return(
            <section className="social-media bg-secondary" id="social-media">
                <div className="container">
                    <h2>We
                        <i className="fa fa-heart heart"></i>
                         new friends!</h2>
                    <ul className="list-inline list-social">
                        <li className="list-inline-item social-instagram">
                            <a href="https://instagram.com/velee.cc" onClick={() => firebaseAnalytics.then((analytics) => {
                                analytics.logEvent("contact_instagram")
                            })}>
                                <i className="fa fa-instagram"></i>
                            </a>
                        </li>

                        <li className="list-inline-item social-instagram">
                            <a href="mailto:stefan@velee.cc" onClick={() => firebaseAnalytics.then((analytics) => {
                                analytics.logEvent("contact_email")
                            })}>
                                <i className="fa fa-envelope-o"></i>
                            </a>
                        </li>
                    </ul>


                </div>
            </section>
        )
    }
}