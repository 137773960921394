import React from 'react';
import {useTranslation} from "react-i18next";

const Footer = () => {
    const {t} = useTranslation();
        return(
            <footer>
                <div className="container">
                    <p>&copy; Velee 2022. All Rights Reserved.</p>
                    <ul className="list-inline">
                        <li className="list-inline-item">
                            <a href={process.env.PUBLIC_URL + "#/privacy"}>{t("privacy")}</a>
                        </li>
                        <li className="list-inline-item">
                            <a href={process.env.PUBLIC_URL + "#/legal"}>{t("legal")}</a>
                        </li>
                    </ul>
                </div>
            </footer>
        )
}

export default Footer