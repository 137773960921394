import {firebaseAnalytics} from "../firebase/firebase-init";

const Redirect = () => {

    const doRedirect = () => {
        let device = getMobileOperatingSystem()
        let link = window.location.href;

        link = link.split("?")

        let mobile = false;
        let url = "https://velee.cc"
        if (device === "ios") {
            mobile = true;
            url = "https://apps.apple.com/de/app/stansday/id1539169967"
        } else if (device === "android") {
            mobile = true;
            url = "https://play.google.com/store/apps/details?id=com.stansday.app"
        }

        if (link[1].startsWith("activity")) {
            firebaseAnalytics.then((analytics) => {
                analytics.logEvent("share_activity")
            });
            let activityId = link[1].split("=")
            redirectToUri(url, "activity", activityId[1], mobile)
        } else if (link[1].startsWith("club")) {
            firebaseAnalytics.then((analytics) => {
                analytics.logEvent("share_club")
            });
            let club = link[1].split("=")
            redirectToUri(url, "club", club[1], mobile)
        }
        else if (link[1].startsWith("route")) {
            firebaseAnalytics.then((analytics) => {
                analytics.logEvent("share_route")
            });
            let routeId = link[1].split("=")
            redirectToUri(url, "route", routeId[1], mobile)
        }
    };


    const redirectToUri = (url, type, id, mobile) => {
        setTimeout(function () {
            window.location = url;
        }, 25);
        if(mobile) {
            window.location = `stansday://${type}/${id}`;
        }

    }

    const getMobileOperatingSystem = () => {
        let userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {

            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {

            return "android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {

            return "ios";
        }

        return "unknown";
    }


    doRedirect()

    return true;



}

export default Redirect;