import React, {useState} from 'react';
import Logo from '../img/velee-150.png';
import axios from 'axios';
import {useTranslation} from "react-i18next";

const ForgotPassword = () => {
    const {t} = useTranslation();

    const [password, setPassword] = useState(null)
    const [repeatPassword, setRepeatPassword] = useState(null)


    const _submit = (e) => {
        e.preventDefault();
        let code = null;
        let sPageURL = window.location.href
        let sURLVariables = sPageURL.split('?');

        for (let i = 0; i < sURLVariables.length; i++) {
            let sParameterName = sURLVariables[i].split('=');
            code = sParameterName[1];
        }
        if (password !== null) {
            // check if passwords are equal
            if (password !== repeatPassword) {
                alert(t("reset-password-error-equals"))
            } else {
                axios.post('https://api.stansday.com/auth/reset-password', {
                    code: code,
                    password: password,
                    passwordConfirmation: repeatPassword,
                })
                    .then(function (response) {
                        alert(t("reset-password-success"));
                        window.location.href = "http://velee.cc";
                    })
                    .catch(function (error) {
                        alert(t("reset-password-error"))

                    });
            }
        }
    };


    return (
        <div className="password">
            <form className="form-password" id="forgot-password">
                <div className="text-center mb-4">
                    <img className="mb-4" src={Logo} width="300" alt=""/>

                    <p className="text-light">{t("reset-password-text")}</p>
                </div>

                <div className="form-label-group">
                    <input type="password" id="inputPassword" className="form-control" placeholder="Password"
                           required onChange={(e) => setPassword(e.target.value)}/>
                    <label htmlFor="inputPassword">Password</label>
                </div>

                <div className="form-label-group">
                    <input type="password" id="inputRepeatPassword" className="form-control"
                           placeholder="Password"
                           required onChange={(e) => setRepeatPassword(e.target.value)}/>
                    <label htmlFor="inputPassword">Repeat Password</label>
                </div>

                <button className="btn btn-lg btn-secondary btn-block"
                        onClick={(e) => _submit(e)}>{t("reset-password-button")}
                </button>
                <p className="mt-5 mb-3 text-light text-center">&copy; Velee 2022</p>
            </form>
        </div>
    )
}

export default ForgotPassword